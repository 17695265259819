<template>
  <ol>
    <li v-for="(term,k) in listExpand" :key="k">
        <a>{{term}}</a>
    </li>
  </ol>
</template>

<script>
export default {
  name: 'listExpand',
    props: {
      listExpand: {
        default: () => []
      },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.side-margin{
  margin: 0% 1%
}
.btm-margin{
    margin-bottom: 3%;
}
.tp-margin{
    margin-top: 3%;
}
.card-body{
  font-size: 0.7rem;
  padding: 1rem !important;
}

</style>