<template>
<div class="tp-margin" style="margin: 2% 2.5%; width: 95%;">
    <!-- <b-table hover :items=jsonResult :fields="fields"></b-table> -->
    <div class="row">
        <h2>{{scannerName}} Results</h2>
      </div>
      <h2 v-if="isEmpty" style="margin: 1.5% 0%">No results for {{scannerName}}</h2>
       <ve-table
       v-else
       style="margin: 1.5% 0%"
       id="ScannerResult_table_id"
       class="ScannerResult_table"
       row-key-field-name="rowKey"
       :cell-style-option="cellStyleOption" 
        :scroll-width="width"
       :columns="columns" 
       :expand-option="expandOption"
       :table-data= jsonResult>
       </ve-table>
<div>
    <button @click="downloadExcel">Download Excel</button>
</div>
</div>
</template>

<style>
.table-body-cell-class1 {
    background: #91d5ff !important;
    color: #fff !important;
}

.table-body-cell-class2 {
    background: orange !important;
    color: #fff !important;
}

.table-body-cell-class4 {
    background: lightcoral !important;
    color: #fff !important;
}
.table-body-cell-class6 {
    background: lightgreen !important;
    color: #fff !important;
}

.table-body-cell-class5 {
    overflow-x: auto !important;
}
</style>

<script>
import exportFromJSON from 'export-from-json';
export default {
    name: 'scannerResult',
    props: {
        scannerName: {
            required: true
        },
        jsonResult: {
            required: true,
            default: () => []
        },
        columns: {
            required: true,
            default: () => []
        },
        cellStyleOption: {
            required: true
        },
        width: {
            required: true
        },
        expandOption: {
            required: false,     
        },
    },
    computed: {
        isEmpty: function () {
            if (this.jsonResult.length) {
                return this.jsonResult.length == 0;
            } else {
                return true;
            }

        }
    },
    mounted(){
        console.log("-------------------------------------------------")
        console.log(this.props.expandOption)
        console.log("-------------------------------------------------")
    },
      methods: {
        downloadExcel() {      
      const data = this.jsonResult;
      const fileName = `${this.scannerName}_results.xlsx`;

      // Convert the data to the required format for exportFromJSON
      //const exportData = data.map(row => columns.reduce((acc, column) => ({ ...acc, [column]: row[column] }), {}));

      // Export the data as an Excel file
      exportFromJSON({ data, fileName, exportType: 'csv' });

    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.side-margin{
  margin: 0% 1%
}
.btm-margin{
    margin-bottom: 3%;
}
.tp-margin{
    margin-top: 7%;
}
.ScannerResult_table{
    position: relative;
    table-layout: fixed;
    border-collapse: separate;
}
#ScannerResult_table_id:first-child{
      min-height: 120px !important;
}
</style>
